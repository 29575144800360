<template>
    <section class="gardenManagementPage">
        <!-- <g-breadcrumb bread="会员管理" /> -->
        <SearchForm>
            <div class="dis-flex flex-x-between flex-y-start">
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item>
					<el-input v-model.trim="searchForm.contactPhone" placeholder="请输入员工姓名" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="findDcUser">搜 索</el-button>

				</el-form-item>
               
			</el-form>
            <el-button type="primary" @click="addUser" >添加用户</el-button>
        </div>
		</SearchForm>
   
        <TableContainer title="">
            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="id" label="员工id" min-width="60" />
                <el-table-column prop="fullName" label="员工姓名" min-width="60" />
                <el-table-column prop="roleName" label="所属部门" min-width="60" />
                <el-table-column prop="contactPhone" label="手机号码" min-width="60" />
                <el-table-column prop="openid" label="openID" min-width="60" />
                   
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="textOrange" @click="delteFormClick(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="findDcUser" :page-sizes="pageSizes"/>
        </TableContainer>
        <GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm()" @btnClose="resetThemeForm()" width="50%">
            <el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px" @submit.native.prevent
                class="dis-flex flex-dir-column flex-x-start">
                <el-form-item label="部门" >
                    <el-select v-model="estimatedAttributionId" placeholder="请选择部门" clearable @clear="handleClearPart">
                        <el-option v-for="(item, index) in partList" :key="index" :label="item.organizationName" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="角色" >
                    <el-select v-model="roleId" placeholder="请选择角色" clearable @clear="handleClearRule">
                        <el-option v-for="(item, index) in ruleList" :key="index" :label="item.roleName" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="是否报餐" label-width="100px">
                    <el-radio v-model="isDeal" label="0">报餐</el-radio>
                    <el-radio v-model="isDeal" label="1">不报餐</el-radio>
                </el-form-item>
                <el-form-item label="人员姓名" label-width="100px">
					<el-input v-model.trim="fullName" placeholder="请输入姓名" />
				</el-form-item>
                <el-form-item label="电话号码" label-width="100px">
					<el-input v-model.trim="contactPhone" placeholder="请输入电话号码" />
				</el-form-item>
             
            </el-form>

        </GDialog>
    </section>
</template>

<script>
import {
    findDcUser,
    selectMinDcDept,
    selectRule,
    addBxUser,
    delBxUser
} from '@api/bx/api_contentManagement';
export default {
    data() {
        return {
            themeDialog: {
                title: '添加用户信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 15,
                contactPhone:''
            },
            total: 0,
            tableData: [],
            pageSizes:[15, 30, 45, 60, 75],
            partList:[],
            ruleList:[],
            roleId:'',
            estimatedAttributionId:'',
            isDeal:'0',
            fullName:'',
            contactPhone:'',
        }
    },
    created() {
        this.findDcUser()
        this.selectMinDcDept()
        this.selectRule()


    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },
        addUser(){
            this.estimatedAttributionId = ''
            this.roleId =''
            this.isDeal='0'
            this.fullName=''
            this.contactPhone=''
            this.themeDialog.visible = true
        },
         //确定按钮
         submitThemeForm() {
            if (this.estimatedAttributionId == '') {
                this.$message.warning('请选择部门')
                return
            }
            if (this.roleId == '') {
                this.$message.warning('请选择角色')
                return
            }
            if (this.fullName == '') {
                this.$message.warning('请输入人员姓名')
                return
            }
            if (this.contactPhone =='') {
                this.$message.warning('请正确填写人员号码')
                return
            }
            if (this.contactPhone.length!==11) {
                this.$message.warning('请正确填写人员号码')
                return
            }
            addBxUser({
                estimatedAttributionId:this.estimatedAttributionId,
                roleId:this.roleId,
                fullName:this.fullName,
                contactPhone:this.contactPhone,
                isDeal:this.isDeal,
            }).then(res => {
                this.findDcUser()
                this.resetThemeForm()

            })
           
         },

        //取消按钮
        resetThemeForm() {
            this.estimatedAttributionId =''
            this.roleId =''
            this.isDeal='0'
            this.fullName=''
            this.contactPhone=''
            this.themeDialog.visible = false
        },
        // 删除
        delteFormClick(row){
            this.$confirm('永久删除' +row.fullName +'用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delBxUser({ id: row.id }).then(res => {
                    this.$message.success('删除成功')
                	this.findDcUser()
                })
            })

        },

        // 会员管理列表
        findDcUser() {
            findDcUser(this.searchForm).then(res => {
                this.tableData = res.records
				this.total = res.total

            })
        },
      
         //部门列表
         selectMinDcDept() {
            selectMinDcDept({}).then(res => {
                this.partList = res

            })
        },

            // 角色列表
            selectRule() {
                selectRule({}).then(res => {
                this.ruleList = res


            })
        },
        handleClearPart(){
           
            this.estimatedAttributionId=''
        },
       
        handleClearRule(){
            this.roleId=''
           
        }

    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

